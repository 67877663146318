<template>
  <div class="classify-list">
    <!-- 标题 -->
    <div v-for="(item, index) in FloorList" :key="index">
      <div class="list">
        <div class="title">
          <div class="name">{{ item.name }}</div>
          <div class="t-list">
            <li
              class="l-item"
              v-for="(vala, keya) in item.list"
              :key="keya"
              @mouseenter="enter(index, keya)"
            >
              <a>{{ vala.name }}</a>
            </li>
          </div>
        </div>
      </div>
      <!-- 商品展示-->
      <div class="show_tab">
        <div class="item_left"></div>
        <div class="item_right">
          <div class="item" v-for="(val, key) in foor[index]" :key="key">
            <div class="pictrue" @click="togoods(val.id)">
              <img :src="val.img" />
            </div>
            <div class="price">
              <!-- <span class="font-color">¥{{ val.price }}</span> -->
              <span class="font-color">待定</span>
              <!-- <span class="y_money">¥{{ val.originalprice }}</span> -->
            </div>
            <div class="i-name" @click="togoods(val.id)">
              <a href="#">{{ val.goods_name }}</a>
            </div>
            <div class="i-footer" @click="tostore(val.store_id)">
              <div class="store-name">
                <a>{{ val.store_name }}</a>
              </div>
              <div class="sales">
                成交量:&nbsp;<span>{{ val.browse }}笔</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "classifyList",
    data() {
      return {
        foor: []
      }
    },
    props: {
      FloorList: {
        type: Array
      }
    },
    watch: {
      FloorList() {
        for (var i = 0; i < this.FloorList.length; i++) {
          this.foor.push(this.FloorList[i].list[0].goods)
        }
      }
    },
    methods: {
      enter(index, key) {
        this.$set(this.foor, index, this.FloorList[index].list[key].goods)
      },
      togoods(id) {
        let routeUrl = this.$router.resolve({
          path: "/store/goods",
          query: { id: id }
        });
        window.open(routeUrl.href, '_blank');
      },
      tostore(id) {
        let routeUrl = this.$router.resolve({
          path: "/store/home",
          query: { id: id }
        });
        window.open(routeUrl.href, '_blank');
      },

    },
  }
</script>

<style lang="less" scoped>
.classify-list {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 20px;

  .list {
    margin-top: 30px;

    .title {
      display: flex;
      justify-content: space-between;

      .name {
        font-size: 18px;
        font-weight: bold;
        color: #333;
      }

      .t-list {
        display: flex;

        .l-item {
          height: 26px;
          font-size: 14px;
          line-height: 26px;
          text-align: center;
          box-sizing: border-box;
          margin-left: 20px;

          a {
            color: #333;
          }

          &:hover {
            border-bottom: 2px solid #e1251b;

            a {
              color: #e1251b;
            }
          }
        }
      }
    }
  }

  .show_tab {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    .item_left {
      width: 234px;
      height: 694px;
      box-sizing: border-box;
      background: #ffffff;
    }

    .item_right {
      width: 966px;

      .item {
        float: left;
        width: 230px;
        height: 340px;
        box-sizing: border-box;
        cursor: pointer;
        margin-left: 10px;
        margin-bottom: 14px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px;
        background-color: #fff;
        transition: box-shadow 1s;

        &:hover {
          box-shadow: 2px 5px 10px gray;
        }

        .pictrue {
          width: 210px;
          height: 192px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .price {
          .font-color {
            font-size: 20px;
            // font-weight: bold;
            color: #e1251b;
          }

          .y_money {
            color: #aaaaaa;
            font-size: 12px;
            text-decoration: line-through;
            margin-left: 8px;
          }
        }

        .i-name {
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          color: #333;
          font-size: 14px;
        }

        .i-footer {
          font-size: 12px;
          display: flex;
          justify-content: space-between;
          .sales {
            color: #666;
            span {
              color: #e1251b;
            }
          }

          .store-name {
            width: 120px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
</style>