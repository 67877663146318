<template>
  <div class="hot-products">
    <ul class="p-container">
      <li class="c-item" v-for="(item, index) in aList" :key="index">
        <a :href="item.adv_link" target="_blank">
          <img :src="item.adv_code" class="swiperImg" />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name:"hotProducts",
    props: {
      aList: {
        type: Array
      },
    },
  }
</script>

<style lang="less" scoped>
.hot-products {
  width: 1200px;
  margin: 0 auto;
  .p-container {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    .c-item {
      width: 290px;
      // height: 160px;
      // //   border: 1px solid #e1251b;
      // transition: box-shadow 1s;
      // background-color: #fff;
      &:hover {
        box-shadow: 2px 5px 10px gray;
      }
      img {
        width: 290px;
        // height: 160px;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
      }
    }
  }
}
</style>