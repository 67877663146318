<template>
  <div class="pc-home" v-loading="loading">
    <Search></Search>
    <components
      :is="swiperList"
      :categoryList="categoryList"
      :swiperImages="swiperImages"
    ></components>
    <hot-products :aList="aList"></hot-products>
    <classify-list :FloorList="FloorList"></classify-list>
  </div>
</template>

<script>
  import Search from '../../components/search.vue'
  import classifyList from './classifyList.vue'
  import hotProducts from './hotProducts.vue'
  import { Initial } from '@/api/index'
  export default {
    components: {
      classifyList,
      hotProducts,
      Search
    },
    data() {
      return {
        swiperImages: [], // 轮播图数据
        FloorList: [],    // 楼层数据
        categoryList: [], // 分类数据
        swiperList: '',
        aList:[],
      }
    },
    methods: {
      async getinfo() {
        this.loading = true;
        const { data: res } = await Initial();
        this.FloorList    = res.result.floorlist;
        this.swiperImages = res.result.rotation;
        this.aList        = res.result.bottomadv;
        this.loading = false;
        this.categoryList = res.result.classify;
        this.$nextTick(() => {
          this.swiperList = () => import('./swiperList.vue')
        })
      }
    },
    created() {
      this.getinfo();
    }
  }
</script>

<style lang="less" scoped>
.pc-home {
  background: #f9f9f9;
}
</style>